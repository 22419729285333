import React from "react";
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Link,
  Container,
} from "@mui/material";

import microrecImage from "./microrec.png";
import camerasImage from "./cameras.png";
import optirecImage from "./optirec.png";

const tools = [
  {
    title: "Smartphone based Optical Systems",
    image: microrecImage,
    link: "/microrec",
  },
  {
    title: "Optical Systems for Professional Cameras",
    image: camerasImage,
    link: "/micro-to-4k",
  },
  {
    title: "Smartphone slit lamp adapter",
    image: optirecImage,
    link: "/optirec",
  },
];

const ToolsPage = () => {
  return (
    <Container maxWidth={"lg"} style={{ textAlign: "center", padding: "20px" }}>
      <h1 style={{ fontSize: "40px", marginBottom: "1em" }}>
        We have the perfect digitalization
        <br /> tool for <b style={{ color: "#FFA100" }}>your practice.</b>
      </h1>
      <Grid container spacing={4} justifyContent="center">
        {tools.map((tool, index) => (
          <Grid item key={index} xs={12} sm={6} md={3}>
            <Link href={tool.link} underline="none">
              <Card
                style={{
                  height: "250px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                  borderRadius: "20px",
                }}
              >
                <CardContent>
                  <div style={{ fontSize: "20px", fontWeight: "700" }}>
                    {tool.title}
                  </div>
                </CardContent>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "150px",
                  }}
                >
                  <CardMedia
                    component="img"
                    alt={tool.title}
                    style={{ width: "100px" }}
                    image={tool.image}
                  />
                </div>
              </Card>
            </Link>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ToolsPage;
